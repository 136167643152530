
import BackgroundVideoPlayer from "@/components/BackgroundVideoPlayer"
import { EvercamApi } from "@evercam/shared/api/evercamApi"

export default {
  components: { BackgroundVideoPlayer },
  layout: "clean",
  middleware: "unauth",
  async asyncData({ query }) {
    return {
      isExpired: query.u === undefined,
    }
  },
  data: () => ({
    loading: false,
    model: {
      password: "",
      confirmPassword: "",
    },
    defaultModel: {
      password: "",
      confirmPassword: "",
    },
  }),
  meta: {
    public: true,
  },
  methods: {
    async resetPassword() {
      try {
        let payload = {
          token: this.$route.query.t,
          password: this.model.password,
        }
        let user = this.$route.query.u
        let response = await EvercamApi.users.updatePassword(user, payload)
        this.$notifications.success(response.message)
        this.model = { ...this.defaultModel }
        this.$refs.observer.reset()
      } catch (error) {
        if (error.response?.data?.code?.includes("INVALID_TOKEN")) {
          this.isExpired = true

          return
        } else {
          this.$notifications.error({
            text: this.$i18n.t("content.auth.reset_password_failed"),
            error,
          })
        }
      } finally {
        this.loading = false
      }
    },
  },
}
